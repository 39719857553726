import React from "react";
import Page from "../components/Page";

import SEO from "../components/SEO";
import { globalSettings } from "../global/settings";

const NotFoundPage = () => (
  <>
    <SEO {...globalSettings} title="404: Not found" />

    <Page title="404- NOT FOUND">
      <p>This page doesn't exist, sorry.</p>
    </Page>
  </>
);

export default NotFoundPage;
