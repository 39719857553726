import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { breakpointMixins } from "../../styles/breakpoints";
import { textStyles } from "../../styles/type";

const { spacing, contentWidth } = variables;
export const styles: EmotionStyles = {
  container: {
    paddingTop: variables.spacing.medium,
    [breakpointMixins.lg]: {
      paddingTop: variables.spacing.large
    }
  },
  content: {
    display: "flex",
    flexDirection: "column-reverse",
    ...textStyles.bodyM,

    [breakpointMixins.md]: {
      flexDirection: "row"
    },
    [breakpointMixins.lg]: {
      "& p": {
        maxWidth: contentWidth
      }
    }
  },
  content_vertical: {
    display: "block"
  },
  hasImage: {
    "& figure": {
      minWidth: 250,
      margin: `0 auto ${spacing.medium}`,
      width: "100%",
      [breakpointMixins.md]: {
        width: "100%",
        margin: `0 0 0 ${spacing.medium}`
      },
      [breakpointMixins.lg]: {
        margin: `0 0 0 ${spacing.large}`,
        flex: 1
      }
    }
  },
  contentInner: {
    flexBasis: "-moz-available",
    [breakpointMixins.lg]: {
      flex: 2
    }
  }
};
