import React from "react";
import PortableText from "../PortableText";
import { styles } from "./styles";
import { PageInterface } from "../../interfaces/page";
import Figure from "../Figure";
import Heading from "../Heading";
import Inner from "../Inner";
import BuyMeACoffee from "../BuyMeACoffee";

const Page: React.FC<PageInterface> = ({
  _rawBody,
  title,
  mainImage,
  layout = "horizontal",
  buyMeACoffee,
  children
}) => {
  return (
    <div css={styles.container}>
      <Inner>
        {title && <Heading headingLevel="h1">{title}</Heading>}
        <div
          css={[
            styles.content,
            mainImage && styles.hasImage,
            styles[`content_${layout}`]
          ]}
        >
          {_rawBody && (
            <div css={styles.contentInner}>
              <PortableText blocks={_rawBody} />
              {buyMeACoffee && <BuyMeACoffee />}
            </div>
          )}
          {mainImage && mainImage.asset && <Figure node={mainImage} />}
          {children}
        </div>
      </Inner>
    </div>
  );
};

export default Page;
